import { debounce } from "@/lib/utils/debounce";
import { randomName } from "@/lib/utils/random";

export type Event = {
  timeDelta: number;
  name: string;
  params: Record<string, any>;
};

const enabled = false;

type StatusCallback = () => Record<string, any>;

export class ReqportingQueue {
  events: Event[] = [];
  sessionId: string = randomName(8);
  previousTime: number = 0;
  statusCallback: StatusCallback | undefined;
  inactivityTimer: any;

  saveRateLimited = debounce(2000, () => {
    this.save();
  });

  constructor() {}

  setStatusCallback(callback: StatusCallback) {
    this.statusCallback = callback;
  }

  add(name: string, params: Record<string, any>) {
    if (!enabled) return;
    const currentTime = performance.now();
    this.events.push({
      name,
      params,
      timeDelta: roundMillisecondToDecimalSecond(
        currentTime - this.previousTime
      ),
    });
    this.previousTime = currentTime;
    this.saveRateLimited();
    this.rescheduleInactivityTimer();
  }

  rescheduleInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => {
      const params = this.statusCallback ? this.statusCallback() : {};
      this.add("inactive", params);
    }, 60 * 1000);
  }

  save() {
    const events = this.events;
    this.events = [];

    if (events.length === 0) {
      return;
    }

    const isAdmin =
      window.localStorage.getItem("metrics-disabled") === "yes" ||
      process.env["NODE_ENV"] === "development";

    const body = JSON.stringify({
      sessionId: this.sessionId,
      events,
      isAdmin,
    });
    fetch("/api/usage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error("Failed to save usage data", res);
        }
      })
      .catch((err) => {
        console.error("Failed to save usage data", err);
      });
  }
}

function roundMillisecondToDecimalSecond(ms: number) {
  return Math.round(ms / 100) / 10;
}
