export const listenForNextOccurrence = (
  element: HTMLElement,
  name: string
): Promise<Event> => {
  return new Promise((resolve) => {
    const modifiedListener = (event: Event) => {
      element.removeEventListener(name, modifiedListener);
      resolve(event);
    };
    element.addEventListener(name, modifiedListener);
  });
};
