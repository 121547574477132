import { sleep } from "@/lib/utils/sleep";
import {
  makeAutoObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import {
  AudioPlayerSettings,
  AudioPlayerState,
  AudioPlayerStateCode,
} from "./AudioPlayerState";
import { attachPlayerEventListeners } from "./player-event-listeners";
import debug from "debug";
import { string } from "prop-types";
import { listenForNextOccurrence } from "./listenForNextOccurrence";

const log = debug("player:WebAudioPlayerState");

let lastId = 0;

export class WebAudioPlayerState implements AudioPlayerState {
  type = "web" as const;
  media?: HTMLMediaElement;

  id: number;
  url = "";
  playing = false;
  currentTime = 0;
  duration: number | null = null;
  playbackRate = 1;

  _onMediaCallbacks: ((media: HTMLMediaElement) => void)[] = [];

  state: AudioPlayerStateCode = "idle";

  _failed = false;

  statusMessage: null | string = null;

  eventListeners: {
    [type: string]: EventListenerOrEventListenerObject;
  } = {};

  // Event target for dispatching events to outside listeners
  private _eventTarget: EventTarget = new EventTarget();

  constructor() {
    this.id = ++lastId;
    log(`[${this.id}] %cM > constructor`, "background: pink");
    makeAutoObservable(this, {
      url: observable,
      playing: observable,
      currentTime: observable,
      duration: observable,
      playbackRate: observable,
      state: observable,
      disabled: computed,
      init: action,
      _handlePlay: action,
      _handlePause: action,
      _handleTimeUpdate: action,
      _handleDurationChange: action,
      _handleRateChange: action,
      play: action,
      playFrom: action,
      playUrlFrom: action,
      pause: action,
      addTime: action,
      seekTo: action,
      clearMedia: action,
      updateState: action,
      setPlaybackRate: action,
    });

    if (typeof window !== "undefined") {
      attachPlayerEventListeners(window, this);
    }
  }

  get disabled() {
    return this.duration === null;
  }

  init(media: HTMLMediaElement) {
    log(`[${this.id}`, "%cM > init media", "background: pink");
    this.media = media;

    const addEL = (
      type: string,
      listener: EventListenerOrEventListenerObject
    ) => {
      media.addEventListener(type, listener);
      this.eventListeners[type] = listener;
    };

    addEL("play", () => {
      log(`[${this.id}] play (event)`);
      this.updateState("playing");
      this._handlePlay();
      this._eventTarget.dispatchEvent(new CustomEvent("play"));
    });
    addEL("pause", () => {
      log(`[${this.id}]`, "pause (event)");
      if (this.state === "playing" || this.state === "idle") {
        this.updateState("paused");
      }
      this._handlePause();
      this._eventTarget.dispatchEvent(new CustomEvent("pause"));
    });
    addEL("timeupdate", () => {
      log(`[${this.id}] timeupdate`, media.currentTime);
      this._handleTimeUpdate(media.currentTime);
      this._eventTarget.dispatchEvent(
        new CustomEvent("timeupdate", {
          detail: { currentTime: media.currentTime },
        })
      );
    });
    addEL("durationchange", () => {
      log(`[${this.id}]`, "durationchange", media.duration);
      this._handleDurationChange(media.duration);
      this._eventTarget.dispatchEvent(
        new CustomEvent("durationchange", {
          detail: { duration: media.duration },
        })
      );
    });
    addEL("ratechange", () => {
      this._handleRateChange(media.playbackRate);
      this._eventTarget.dispatchEvent(
        new CustomEvent("ratechange", {
          detail: { playbackRate: media.playbackRate },
        })
      );
    });
    addEL("loadedmetadata", () => {
      log(`[${this.id}]`, "loadedmetadata", media.duration);
    });

    addEL("canplay", () => {
      log(
        `[${this.id}]`,
        "canplay  (the user agent can play the media, but estimates that not enough data has been loaded to play the media up to its end without having to stop for further buffering of content)"
      );
    });
    addEL("canplaythrough", () => {
      log(
        `[${this.id}]`,
        "canplaythrough (the user agent can play the media, and estimates that enough data has been loaded to play the media up to its end without having to stop for further buffering of content)"
      );
    });
    addEL("loadeddata", () => {
      log(
        `[${this.id}]`,
        "loadeddata (the frame at the current playback position of the media has finished loading)"
      );
    });
    addEL("loadedmetadata", () => {
      log(`[${this.id}]`, "loadedmetadata (the metadata has been loaded)");
    });
    addEL("seeking", () => {
      log(`[${this.id}]`, "seeking (seek operation started)");
    });
    addEL("seeked", () => {
      log(
        `[${this.id}]`,
        "seeked (seek operation completed, the current playback position has changed)"
      );
      if (this.state === "waiting") {
        if (this.media) {
          this.updateState(this.media.paused ? "paused" : "playing");
        }
      }
    });
    addEL("stalled", () => {
      if (this.state !== "playing" && this.state !== "paused") {
        // received 'stalled' event after 'play' event
        this.updateState("stalled");
      }
      log(
        `[${this.id}]`,
        "stalled (trying to fetch media data, but data is unexpectedly not forthcoming)"
      );
    });
    addEL("suspend", () => {
      // this.updateState("suspended");
      log(`[${this.id}]`, "suspend (media data loading has been suspended)");
    });
    addEL("waiting", () => {
      // 'waiting' can be fired after the 'play' event
      if (this.state !== "playing" && this.state !== "paused") {
        this.updateState("waiting");
      }
      log(
        `[${this.id}]`,
        "waiting (playback has stopped because of a temporary lack of data)"
      );
    });
    addEL("error", (event: any) => {
      log(
        `[${this.id}]`,
        "error (the resource could not be loaded due to an error)"
      );
      console.error(
        "Media loading error:",
        this.media ? this.media.error : null
      );
      this.updateState("failed");
      this._failed = true;
    });

    this._handleDurationChange(media.duration);

    this._onMediaCallbacks.forEach((callback) => callback(media));
    this._onMediaCallbacks = [];
  }

  get error() {
    return this.media ? this.media.error : null;
  }

  get isReady() {
    return !!this.media;
  }

  _handlePlay() {
    this.playing = true;
  }

  _handlePause() {
    this.playing = false;
  }

  _handleTimeUpdate(t: number) {
    this.currentTime = Math.round(t);
  }

  _handleDurationChange(d: number) {
    log("duration changed", d);
    if (typeof d === "number" && d.toString() !== "NaN") {
      this.duration = Math.round(d);
    } else {
      this.duration = null;
    }
  }
  _handleRateChange(r: number) {
    this.playbackRate = r;
  }

  play() {
    if (this.media) {
      log("media.play()");
      if (this._failed) {
        this._failed = false;
        this.media.load();
      }
      this.updateState("loading");
      const playPromise = this.media.play();
      if (playPromise) {
        playPromise
          .then(() => {
            log("play promise resolved");
            this.updateState("playing");
          })
          .catch((e) => {
            log("play promise rejected", e);
            this.updateState("paused");
          });
      }
    } else {
      log("no media to play");
    }
  }
  playFrom(seconds: number) {
    log("playFrom", seconds);
    if (this.media) {
      this.media.currentTime = seconds;
      this.play();
      // log("media.play()");
      // this.media.play();
    } else {
      log("no media to play");
    }
  }

  setUrl(url: string) {
    this.playUrlFrom(url, null, false);
  }
  playUrlFrom(url: string, seconds: number | null, play = true) {
    log("%cplayUrlFrom", "background: green", {
      url,
      seconds,
      play,
    });

    this.duration = null;
    this.url = url;
    // The media might be set after this function is called, so we need to wait
    this.onMedia(() => {
      log("%cM > onMedia callback called", "background: yellow");

      // When playing offline, using the URL directly may cause seeking not to work,
      // so instead we load the whole audio blob into memory
      // TODO: do this only when we're serving the audio from cache
      // fetch(url)
      //   .then((r) => r.blob())
      //   .then((blob) => {
      runInAction(() => {
        if (!this.media) {
          console.error("M >", "onMedia callback called with no media");
          return;
        }

        // this.media.src = "";
        // this.media.src = URL.createObjectURL(blob);
        this.media.src = url;

        this.media.currentTime = seconds ?? 0;
        if (play) {
          // log("media.play()");
          // this.media.play();
          this.play();
        } else {
          log("media.pause()");
          this.media.pause();
        }
      });
      // });
    });
  }
  pause() {
    if (this.media) {
      this.media.pause();
    }
  }
  addTime(seconds: number) {
    if (this.media) {
      this.media.currentTime += seconds;
    }
  }

  seekTo(seconds: number) {
    log("seekTo", seconds);
    if (this.media) {
      this.media.currentTime = seconds;
    } else {
      log("no media to seek");
    }
  }

  clearMedia() {
    log(`[${this.id}]`, "clearMedia");
    this.pause();
    this.playing = false;
    this.currentTime = 0;
    this.duration = null;
    const media = this.media;
    if (media) {
      Object.entries(this.eventListeners).forEach(([type, listener]) => {
        media.removeEventListener(type, listener);
      });
      // Make it impossible to continue playing (e.g. in response to a media button)
      // media.src = "";
      this.media = undefined;
    }
  }

  rewind({ shift, alt }: { shift: boolean; alt: boolean }) {
    const seconds = shift ? 30 : alt ? 10 : 5;
    this.addTime(-seconds);
  }

  forward({ shift, alt }: { shift: boolean; alt: boolean }) {
    const seconds = shift ? 30 : alt ? 10 : 5;
    this.addTime(seconds);
  }

  togglePlay() {
    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  onMedia(callback: (media: HTMLMediaElement) => void) {
    if (this.media) {
      callback(this.media);
    }
    this._onMediaCallbacks.push(callback);
  }

  async playSegment(
    startTime: number,
    endTime: number,
    options: {
      shouldContinuePlaying?: () => boolean;
      onFinish?: () => void;
    } = {}
  ) {
    // endTime: a function can be passed, if the function returns null, it will continue to play

    const media = this.media;
    if (!media) {
      console.warn("No media to play");
      return;
    }
    log("setting currentTime to", startTime);
    if (Math.abs(media.currentTime - startTime) > 0.02) {
      // console.log("SEEK", startTime, "diff:", media.currentTime - startTime);
      media.currentTime = startTime;
      await listenForNextOccurrence(media, "timeupdate");
    } else {
      // console.log("CONT", startTime, "diff:", media.currentTime - startTime);
    }

    media.play();

    let timeout: NodeJS.Timeout | null = null;

    const stop = () => {
      media.pause();
      if (options.onFinish) options.onFinish();
      return;
    };

    const check = () => {
      if (options.shouldContinuePlaying && options.shouldContinuePlaying())
        return;
      const t = media.currentTime;
      const remainingTime = endTime - t;
      if (timeout) {
        clearTimeout(timeout);
      }
      if (remainingTime < 0) {
        log(`[${t}] PAUSING (over by ${-remainingTime}})`);
        stop();
        return;
      }
      log(`[${t}] + ${remainingTime} = ${endTime} => rescheduling`);
      timeout = setTimeout(() => {
        check();
      }, remainingTime * 1000 * 0.8);
    };

    check();
  }

  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject
  ): void {
    this._eventTarget.addEventListener(type, listener);
  }

  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject
  ): void {
    this._eventTarget.removeEventListener(type, listener);
  }

  onNextPlay(callback: () => void) {
    this._eventTarget.addEventListener("play", callback);
  }

  get preciseCurrentTime() {
    if (this.media) {
      return this.media.currentTime;
    }
    return undefined;
  }

  updateState(newState: AudioPlayerStateCode) {
    this.state = newState;
  }

  setPlaybackRate(rate: number) {
    this.playbackRate = rate;
    this.onMedia((media) => {
      media.playbackRate = rate;
    });
  }

  applySettings(settings: AudioPlayerSettings) {
    this.onMedia((media) => {
      if (typeof settings.volume === "number") {
        media.volume = settings.volume / 100;
      }
      if (typeof settings.playbackRate === "number") {
        media.playbackRate = settings.playbackRate;
      }
    });
  }
}
