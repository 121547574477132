export const isEditableElementFocussed = () => {
  const activeElement = document.activeElement;
  if (activeElement) {
    const tagName = activeElement.tagName.toLowerCase();
    return (
      tagName === "input" ||
      tagName === "textarea" ||
      tagName === "select" ||
      activeElement.attributes.getNamedItem("contenteditable")?.value === "true"
    );
  }
  return false;
};
