import React from "react";
import { AudioPlayerState } from "./AudioPlayerState";

type ContextStruct = {
  playerState: AudioPlayerState | null;
};

export const PlayerContext = React.createContext<ContextStruct>({
  playerState: null,
});
