import { v4 as uuidv4 } from "uuid";

export function generateAccessKey(len = 15) {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  return Array(len)
    .fill(null)
    .map((_) => chars[Math.floor(Math.random() * chars.length)])
    .join("");
}

export function randomName(len = 15) {
  const chars = "abcdefghijklmnopqrstuvwxyz";
  return Array(len)
    .fill(null)
    .map((_) => chars[Math.floor(Math.random() * chars.length)])
    .join("");
}

export function randomVoucherCode(len = 6) {
  const chars = "ABCDEFGHJKMNPQRSTUVWXYZ23456789";
  return Array(len)
    .fill(null)
    .map((_) => chars[Math.floor(Math.random() * chars.length)])
    .join("");
}

export function randomUUID() {
  return uuidv4();
}

export function randomItem<T>(items: T[]): T {
  return items[randomIndex(items)];
}

export function randomIndex<T>(items: T[]): number {
  return Math.floor(Math.random() * items.length);
}
