import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { isEditableElementFocussed } from "@/lib/utils/browser-form";
import { AudioPlayerState } from "./AudioPlayerState";

// Handles Space, <-, -> keys
//
// Called by WebAudioPlayerState & CordovaAudioPlayerState
//
// Related: src/modules/keyboard-shortcuts/keyboard-shortcuts.ts
//
export function attachPlayerEventListeners(
  window: Window,
  playerState: AudioPlayerState
) {
  const handleKeyDown = (event: KeyboardEvent) => {
    console.log("key", event.key);
    const ev = event;
    let e;
    if (isEditableElementFocussed() && !ev.ctrlKey && !ev.altKey) {
      return;
    }
    switch (ev.key) {
      case " ": // Space
        ev.preventDefault();
        recordEvent("press-space");
        playerState.togglePlay();
        break;
      case "ArrowLeft":
        recordEvent("press-arrow-left");
        playerState.rewind({ shift: ev.shiftKey, alt: ev.altKey });
        event.preventDefault();
        break;
      case "ArrowRight":
        recordEvent("press-arrow-right");
        playerState.forward({ shift: ev.shiftKey, alt: ev.altKey });
        event.preventDefault();
        break;
    }
  };

  window.addEventListener("keydown", (e) => {
    handleKeyDown(e);
  });
}
