import { ReqportingQueue } from "./ReportingQueue";
import debug from "debug";

const log = debug("usage:recordEvent");

export const reportingQueue = new ReqportingQueue();

export function recordEvent(name: string, params: Record<string, any> = {}) {
  log(name, params);

  reportingQueue.add(name, params);
}
